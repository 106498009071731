import * as React from 'react';

const Footer = props => {
    return (
		<footer>
			<p>Copyright &copy; 2022. All Rights Reserved.</p>
		</footer>
	)
}

export default Footer
